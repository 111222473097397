import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import ReportPercentileBar from './ReportPercentileBar';
import { useAuthServiceContext } from '../context/authContext';
import { useResultService } from '../services/resultService';
import ResultPageLoading from './ResultPageLoading';
import {
  redFlag,
  yellowFlag,
  orangeFlag,
  greenFlag,
  blueFlag,
} from './images/flags';

const ReportVariable = ({
  id = 0,
  result_id = 0,
  is_sub_var = false,
  bar_color = '',
  // coach_only = false,
  description = '',
  display = true,
  flag_ranges = [],
  flag_positive = '',
  flag_negative = '',
  percentile = 0.0,
  percentile_desc = '',
  percentile_interpret = '',
  show_bar = true,
  var_name = '',
}) => {
  if (!display) {
    console.log('var not displayed', var_name, display);
  }
  const componentRef = useRef(null);
  const [flag, setFlag] = useState(null);
  const [flagTitle, setFlagTitle] = useState('');
  const [flagClass, setFlagClass] = useState('');
  const [showFlag, setShowFlag] = useState(false);
  const { user } = useAuthServiceContext();
  const { GetSubvariableDataForResultReport } = useResultService();
  const [subVarData, setSubVarData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [style, setStyle] = useState({});

  // setTimeout(() => {
  //   const newStyle = {
  //     opacity: 1,
  //     width: `${percentileValue}%`,
  //   };

  //   setStyle(newStyle);
  // }, 200);

  const [modifiedDescription, setModifiedDescription] = useState('');

  useEffect(() => {
    if (!display) return;
    //at this time the report only has single level sub-vars, and i wanted less hits to the API
    if (!is_sub_var) {
      GetSubvariableDataForResultReport(result_id, id)
        .then((data) => {
          data.forEach((row) => {
            if (!row.bar_color) row.bar_color = bar_color;
          });
          setSubVarData(data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else setIsLoading(false);
  }, [id, result_id, display]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newDescription = description.replace(
      /\[\[percentile_interpretation\]\]/g,
      percentile_interpret,
    );
    setModifiedDescription(newDescription);
  }, [description, percentile_interpret]);

  useEffect(() => {
    if (user.type === 'COACH') {
      //setup flag
      const matchingFlag = flag_ranges.find((flag) => {
        return percentile >= flag.lower_bound && percentile <= flag.upper_bound;
      });
      const colorMap = {
        red: { img: redFlag, title: 'Red Flag: Substantial concerns' },
        orange: { img: orangeFlag, title: 'Orange Flag: Moderate concerns' },
        yellow: { img: yellowFlag, title: 'Yellow Flag: Mild concerns' },
        green: { img: greenFlag, title: 'Green Flag: Athlete Excels' },
        blue: { img: blueFlag, title: 'Star Flag: Star quality' },
      }; // Update flag and title when a match is found
      if (matchingFlag && colorMap[matchingFlag.color]) {
        setShowFlag(true);
        setFlag(colorMap[matchingFlag.color].img);
        setFlagTitle(colorMap[matchingFlag.color].title);
        setFlagClass(`${matchingFlag.color}_flag`);
      }
    }
  }, [flag_ranges, percentile, user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (componentRef.current) {
      const tabContents = componentRef.current.querySelectorAll(
        '.results_overview, .results_details, .results_sports, .results_questions, .results_flags',
      );
      tabContents.forEach((tab) => {
        tab.classList.add('tab_content');
      });

      const tabs = componentRef.current.querySelectorAll('.tabs');
      tabs.forEach((tabGroup) => {
        tabGroup.setAttribute('data-active-tab', 'results_overview');
        let tab = tabGroup.querySelector('.results_questions');
        if (tab) {
          // Create a new button element
          const newButton = document.createElement('div');
          const textLabel = document.createTextNode('Interview Questions');

          newButton.className = 'tab questions_tab';
          newButton.title = 'Interview Questions';
          newButton.appendChild(textLabel);
          newButton.addEventListener('click', function () {
            this.parentNode.setAttribute('data-active-tab', 'results_overview');
          });
          tabGroup.insertBefore(newButton, tabGroup.firstChild);
        }

        //add flag info tab if flag being shown
        if (user.type === 'COACH') {
          const matchingFlag = flag_ranges.find((flag) => {
            return (
              percentile >= flag.lower_bound && percentile <= flag.upper_bound
            );
          });
          if (matchingFlag) {
            const colorMap = {
              red: { text: flag_negative },
              orange: { text: flag_negative },
              yellow: { text: flag_negative },
              green: { text: flag_positive },
              blue: { text: flag_positive },
            };
            const flag_tab = document.createElement('div');
            flag_tab.className = 'results_flags tab_content';
            flag_tab.innerText = colorMap[matchingFlag.color].text;

            // Create a new button element
            const newButton = document.createElement('div');
            const textLabel = document.createTextNode('Flag Info');

            newButton.className = 'tab flags_tab';
            newButton.title = 'Flag Info';
            newButton.appendChild(textLabel);
            newButton.addEventListener('click', function () {
              this.parentNode.setAttribute('data-active-tab', 'results_flags');
            });
            tabGroup.insertBefore(flag_tab, tabGroup.lastChild);
            // flag_tab.appendChild(newButton);
            tabGroup.insertBefore(newButton, tabGroup.firstChild);
          }
        }

        tab = tabGroup.querySelector('.results_sports');
        if (tab) {
          // Create a new button element
          const newButton = document.createElement('div');
          const textLabel = document.createTextNode('Athletic Insights');

          newButton.className = 'tab sports_tab';
          newButton.title = 'Athletic Insights';
          newButton.appendChild(textLabel);
          newButton.addEventListener('click', function () {
            this.parentNode.setAttribute('data-active-tab', 'results_sports');
          });
          tabGroup.insertBefore(newButton, tabGroup.firstChild);
        }

        tab = tabGroup.querySelector('.results_details');
        if (tab) {
          // Create a new button element
          const newButton = document.createElement('div');
          const textLabel = document.createTextNode('Details');

          newButton.className = 'tab details_tab';
          newButton.title = 'Details';
          newButton.appendChild(textLabel);
          newButton.addEventListener('click', function () {
            this.parentNode.setAttribute('data-active-tab', 'results_details');
          });
          tabGroup.insertBefore(newButton, tabGroup.firstChild);
        }

        tab = tabGroup.querySelector('.results_overview');
        if (tab) {
          // Create a new button element
          const newButton = document.createElement('div');
          const textLabel = document.createTextNode('Overview');

          newButton.className = 'tab overview_tab';
          newButton.title = 'Overview';
          newButton.appendChild(textLabel);
          newButton.addEventListener('click', function () {
            this.parentNode.setAttribute('data-active-tab', 'results_overview');
          });
          tabGroup.insertBefore(newButton, tabGroup.firstChild);
        }
      });

      // update to show high/avg/low predetermined text
      if (componentRef.current) {
        const scoreTexts = componentRef.current.querySelectorAll(
          '.low_score, .average_score, .high_score',
        );

        // Hide all score texts initially
        scoreTexts.forEach((text) => {
          text.style.display = 'none';
        });
        // Show the correct score text based on percentile
        if (percentile > 60) {
          const highScoreText =
            componentRef.current.querySelectorAll('.high_score');
          highScoreText.forEach((high) => {
            high.style.display = 'initial';
          });
        } else if (percentile < 40) {
          const lowScoreText =
            componentRef.current.querySelectorAll('.low_score');
          lowScoreText.forEach((low) => {
            low.style.display = 'initial';
          });
        } else {
          const averageScoreText =
            componentRef.current.querySelectorAll('.average_score');
          averageScoreText.forEach((avg) => {
            avg.style.display = 'initial';
          });
        }
      }
    }
  }, [modifiedDescription]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={componentRef}>
      <div className={is_sub_var ? 'sub-variables' : 'variable'}>
        {is_sub_var ? (
          <h4>
            {var_name}
            {showFlag && (
              <img
                className={flagClass}
                src={flag}
                alt="Flag"
                title={flagTitle}
              />
            )}
          </h4>
        ) : (
          <h3>
            {var_name}
            {showFlag && (
              <img
                className={flagClass}
                src={flag}
                alt="Flag"
                title={flagTitle}
              />
            )}
          </h3>
        )}
        <ReportPercentileBar
          percentileValue={percentile}
          displayText={percentile_desc + ' - ' + percentile_interpret}
          colorNumber={bar_color}
          showBar={show_bar}
        />
        <div dangerouslySetInnerHTML={{ __html: modifiedDescription }} />

        {subVarData
          ? subVarData.map((record, index) => {
              return <ReportVariable key={index} {...record} />;
            })
          : isLoading && <ResultPageLoading />}
      </div>
    </div>
  );
};

ReportVariable.propTypes = {
  id: PropTypes.number,
  result_id: PropTypes.number,
  is_sub_var: PropTypes.bool,
  bar_color: PropTypes.string,
  // coach_only: PropTypes.bool,
  description: PropTypes.string,
  display: PropTypes.bool,
  flag_ranges: PropTypes.array,
  flag_positive: PropTypes.string,
  flag_negative: PropTypes.string,
  percentile: PropTypes.number,
  percentile_desc: PropTypes.string,
  percentile_interpret: PropTypes.string,
  show_bar: PropTypes.bool,
  var_name: PropTypes.string,
};

export default ReportVariable;
