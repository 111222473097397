import React, { useState, useEffect } from 'react';
import { isDirectReportRoute } from './services/authService';
import { useAuthServiceContext } from './context/authContext';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import LoggedInLayout from './components/LoggedInLayout';
import Header from './components/Header';
import Footer from './components/Footer';
import AppRoutes from './AppRoutes';

/// this wrapper allows us to gain access to the authentication services for controlling route rendering
const RouteWrapper = () => {
  const { isLoggedIn } = useAuthServiceContext();
  const [isUnderService, setIsUnderService] = useState(false);

  useEffect(() => {
    setIsUnderService(false);
  }, []);

  return isLoggedIn ? (
    <LoggedInLayout restricted={isDirectReportRoute(location.pathname)}>
      <AppRoutes />
    </LoggedInLayout>
  ) : (
    <>
      <Header />
      <AppRoutes />
      <Footer />
    </>
  );
};

export default RouteWrapper;
