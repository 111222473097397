import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { getIpData } from '../helpers/getIpData';
import { pelotonPaymentCADUrl, pelotonPaymentUSDUrl } from '../environment';
import { useAuthServiceContext } from '../context/authContext';

const PayMessage = ({ user }) => {
  const { logoutUser } = useAuthServiceContext();

  return (
    <Modal
      show={true}
      backdrop="static"
      centered
      style={{ textAlign: 'center' }}
    >
      <Modal.Body>
        <p id="modalTitle">
          Looks like you still need to complete your registration!
        </p>
        <div id="modalActions">
          <Button
            id="payButton"
            variant="primary"
            onClick={() => {
              let ipDetails = getIpData(user.ip_address);
              ipDetails.country === 'CA'
                ? (window.location.href =
                    pelotonPaymentCADUrl + '?param=' + btoa(user.email))
                : (window.location.href =
                    pelotonPaymentUSDUrl + '?param=' + btoa(user.email));
            }}
          >
            Complete Registration
          </Button>
          <a
            id="logout"
            href="#"
            onClick={() => {
              logoutUser();
            }}
          >
            Logout
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p>
          Have you already paid? Let us know so we can fix it, please email{' '}
          <a href="mailto:support@leftbrainperformance.com">
            support@leftbrainperformance.com
          </a>
        </p>
      </Modal.Footer>
    </Modal>
  );
};

PayMessage.propTypes = {
  user: PropTypes.object,
};

export default PayMessage;
