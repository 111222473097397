// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './app/App';
// import { BrowserRouter } from 'react-router-dom';

// ReactDOM.render(
//   <BrowserRouter basename="/">
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root')
// );
import React from 'react';
import { createRoot } from 'react-dom/client'; // Updated import
import './index.css';
import App from './app/App';
import { BrowserRouter } from 'react-router-dom';

// Get the root element
const rootElement = document.getElementById('root');

if (rootElement) {
  // Create a root
  const root = createRoot(rootElement);

  // Render the application
  root.render(
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>,
  );
}
