import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { apiConfig } from '../apiConfig';
import { appURL } from '../environment';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { useAuthServiceContext } from '../context/authContext';

const ProfilePage = () => {
  const { user, refreshUserData } = useAuthServiceContext();
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameSuccess, setNameSuccess] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const changeNameSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
  });
  const changeEmailSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
  });
  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password is too short - should be 8 chars minimum')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain One Uppercase, One Lowercase, One Number and one special case Character',
      ),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  return (
    <Container className="justify-content-center">
      <Row>
        {user && (
          <Col>
            {user.coach_extension && user.coach_extension.referral_code && (
              <div
                className="profileSection"
                data-testid="referral-link-section"
              >
                <Alert variant={'info'}>
                  <div style={{ fontWeight: 'bold' }}>
                    Your referral link is:
                  </div>
                  <a
                    href={`${appURL}/?ref=${user.coach_extension.referral_code}`}
                  >
                    {appURL}/?ref=
                    {user.coach_extension.referral_code}
                  </a>
                </Alert>
              </div>
            )}
            <div className="profileSection">
              <p className="font-weight-bold">Change Name</p>
              <Formik
                initialValues={{
                  firstName: user.first_name,
                  lastName: user.last_name,
                }}
                validationSchema={changeNameSchema}
                onSubmit={(values, { setSubmitting }) => {
                  // When button submits form and form is in the process of submitting, submit button is disabled
                  setSubmitting(true);

                  const data = {
                    first_name: values.firstName.trim(),
                    last_name: values.lastName.trim(),
                  };
                  axios
                    .patch(`${apiConfig.baseUrl}/users/${user.id}/`, data, {
                      withCredentials: true,
                    })
                    .then(() => {
                      // resetForm();
                      setNameError(false);
                      setNameSuccess(true);
                      refreshUserData();
                      setTimeout(() => {
                        setNameSuccess(false);
                      }, 7500);
                    })
                    .catch((err) => {
                      console.log(err, 'update error?');
                      setNameError(true);
                    });

                  // Sets setSubmitting to false after form is reset
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="d-flex flex-column">
                      <Form.Group className="mb-0">
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          isInvalid={
                            touched.firstName && errors.firstName ? true : false
                          }
                        />
                        {touched.firstName && errors.firstName ? (
                          <div className="error-message">
                            {errors.firstName}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="mb-0">
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          isInvalid={
                            touched.lastName && errors.lastName ? true : false
                          }
                        />
                        {touched.lastName && errors.lastName ? (
                          <div className="error-message">{errors.lastName}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group>
                        <Button className="submitButtons" type="submit">
                          Save Name
                        </Button>
                      </Form.Group>
                    </div>
                  </Form>
                )}
              </Formik>
              {nameSuccess && (
                <Alert variant="success">
                  <p className="mb-0">Successfully updated your name.</p>
                </Alert>
              )}
              {nameError && (
                <Alert variant="danger">
                  <p className="mb-0">
                    Oops! Something went wrong. Please try again.
                  </p>
                </Alert>
              )}
              <hr />
            </div>
            <div className="profileSection">
              <p className=" font-weight-bold">Change Email</p>
              <Formik
                initialValues={{
                  email: user.email,
                }}
                validationSchema={changeEmailSchema}
                onSubmit={(values, { setSubmitting }) => {
                  // When button submits form and form is in the process of submitting, submit button is disabled
                  setSubmitting(true);

                  const data = {
                    email: values.email.toLowerCase().trim(),
                  };
                  axios
                    .patch(`${apiConfig.baseUrl}/users/${user.id}/`, data, {
                      withCredentials: true,
                    })
                    .then(() => {
                      // resetForm();
                      setEmailError(false);
                      setEmailSuccess(true);
                      setTimeout(() => {
                        setEmailSuccess(false);
                      }, 7500);
                    })
                    .catch(() => {
                      setEmailError(true);
                    });

                  // Sets setSubmitting to false after form is reset
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="d-flex flex-column">
                      <Form.Group className="mb-0" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          isInvalid={
                            touched.email && errors.email ? true : false
                          }
                        />
                        {touched.email && errors.email ? (
                          <div className="error-message">{errors.email}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group>
                        <Button className="submitButtons" type="submit">
                          Save Email
                        </Button>
                      </Form.Group>
                    </div>
                  </Form>
                )}
              </Formik>
              {emailSuccess && (
                <Alert variant="success">
                  <p className="mb-0">Successfully updated email address.</p>
                </Alert>
              )}
              {emailError && (
                <Alert variant="danger">
                  <p className="mb-0">
                    Oops! Something went wrong. Please try again.
                  </p>
                </Alert>
              )}
              <hr />
            </div>
            <div className="profileSection">
              <p className=" font-weight-bold">Change Password</p>
              <Formik
                initialValues={{
                  currentPassword: '',
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={changePasswordSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  // When button submits form and form is in the process of submitting, submit button is disabled
                  setSubmitting(true);

                  const data = {
                    current_password: values.currentPassword.trim(),
                    password: values.password.trim(),
                    confirm_password: values.confirmPassword.trim(),
                  };
                  axios
                    .patch(`${apiConfig.baseUrl}/users/${user.id}/`, data, {
                      withCredentials: true,
                    })
                    .then(() => {
                      resetForm();
                      setPasswordError(false);
                      setPasswordSuccess(true);
                      setTimeout(() => {
                        setPasswordSuccess(false);
                      }, 7500);
                    })
                    .catch(() => {
                      setPasswordError(true);
                    });

                  // Sets setSubmitting to false after form is reset
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="formContainer">
                      <div className="d-flex flex-column">
                        <Form.Group className="mb-0">
                          <Form.Control
                            type="password"
                            name="currentPassword"
                            placeholder="Current Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.currentPassword}
                            isInvalid={
                              touched.currentPassword && errors.currentPassword
                                ? true
                                : false
                            }
                          />
                          {touched.currentPassword && errors.currentPassword ? (
                            <div className="error-message">
                              {errors.currentPassword}
                            </div>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-0">
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            isInvalid={
                              touched.password && errors.password ? true : false
                            }
                          />
                          {touched.password && errors.password ? (
                            <div className="error-message">
                              {errors.password}
                            </div>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-0">
                          <Form.Control
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            isInvalid={
                              touched.confirmPassword && errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {touched.confirmPassword && errors.confirmPassword ? (
                            <div className="error-message">
                              {errors.confirmPassword}
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                      <Form.Group>
                        <Button className="submitButtons" type="submit">
                          Save Password
                        </Button>
                      </Form.Group>
                    </div>
                  </Form>
                )}
              </Formik>
              {passwordSuccess && (
                <Alert variant="success">
                  <p className="mb-0">Successfully updated your password.</p>
                </Alert>
              )}
              {passwordError && (
                <Alert variant="danger">
                  <p className="mb-0">
                    Oops! Something went wrong. Please try again.
                  </p>
                </Alert>
              )}
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ProfilePage;
