import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiURL } from '../environment';
import { useNavigate } from 'react-router-dom';

//trying to remove the need of publicRoutes and just use helpers/protectedRoute.js instead
const publicRoutes = [
  '/signin',
  '/register',
  '/register/coach',
  '/forgot-password',
  '/resetpassword',
];
export const isPublicRoute = (path) => publicRoutes.includes(path);

export const isDirectReportRoute = (path) => path.search(/\/r\/.{1,}/) >= 0;

export function useAuthService() {
  const navigate = useNavigate();

  function useSecureApiCall() {
    const api = axios.create({
      baseURL: apiURL,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    // Intercept responses to handle token expiration
    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        const error_code = error.response.data.code;
        // If the access token has expired
        if (
          error.response.status === 401 &&
          !originalRequest._retry && //this does not seem to work and isn't returned back?
          isLoggedIn
        ) {
          if (error_code === 'token_not_valid') {
            localStorage.setItem('isLoggedIn', 'false');
            setIsLoggedIn(false);
            setUser(null);
            window.location.href = '/signin'; // Redirect to login if no refresh token exists
            return Promise.reject(error);
          }

          originalRequest._retry = true;
          try {
            await refreshAccessToken();
            return api(originalRequest); // Retry the original request with the new token
          } catch (refreshError) {
            console.log(`refresh error: ${refreshError}`);
            await logoutUser();
            window.location.href = '/signin'; // Redirect to login if refresh fails
          }
        }

        return Promise.reject(error);
      },
    );

    return api;
  }

  const api = useSecureApiCall();
  const [user, setUser] = useState(null);
  const refreshAccessToken = async () => {
    const res = await api.post(`/token/refresh/`);
    return res.data['user'];
  };

  useEffect(() => {
    // Load user on page refresh
    refreshAccessToken()
      .then((refreshedUser) => {
        if (refreshedUser) {
          setUser(refreshedUser);
          localStorage.setItem('isLoggedIn', 'true');
          setIsLoggedIn(true);
        } else {
          localStorage.setItem('isLoggedIn', 'false');
          setIsLoggedIn(false);
        }
      })
      .catch(() => {
        console.log('refresh user failed');
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialLoggedInValue = () => {
    const loggedIn = localStorage.getItem('isLoggedIn');
    return loggedIn !== null && loggedIn === 'true';
  };
  const [isLoggedIn, setIsLoggedIn] = useState(getInitialLoggedInValue);

  useEffect(() => {
    handleRouteChange();
  }, [location.pathname, user, isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRouteChange = () => {
    if (isPublicRoute(location.pathname)) return;

    if (!user) return;

    if (user.type !== 'COACH' && user.has_paid === false) {
      navigate('/portal');
    }
  };

  const loginUser = async (email, password) => {
    const data = {
      email: email.toLowerCase().trim(),
      password: password,
    };
    try {
      const res = await api.post('/token/', data);
      setUser(res.data['user']);
      localStorage.setItem('isLoggedIn', 'true');
      setIsLoggedIn(true);

      return res.status;
    } catch (err) {
      console.log(err);
      return err.response.status;
    }
  };

  const logoutUser = async ({ redirUrl = '', redirParam = '' } = {}) => {
    await api
      .post('/logout/')
      .catch((err) => {
        console.log(err, 'logout error');
      })
      .finally(() => {
        localStorage.setItem('isLoggedIn', 'false');
        setIsLoggedIn(false);
        setUser(null);

        // Set default redirect URL if not provided
        if (!redirUrl) redirUrl = '/signin';
        if (redirParam) redirUrl += '?' + redirParam;

        window.location = redirUrl;
      });
  };

  const getCurrentUserSchoolId = () => {
    // find the coach's school id
    let coach_school = null;
    if (
      user.schools_shared_to !== undefined &&
      user.schools_shared_to.length > 0
    ) {
      coach_school = user.schools_shared_to[0].id;
    }
    return coach_school;
  };

  const refreshUserData = async () => {
    // Load user on page refresh
    let refreshedUser = null;
    try {
      refreshedUser = await refreshAccessToken();
      // if (refreshedUser) setUser(refreshedUser);
    } catch (err) {
      console.log(err);
      // setUser(null);
    }
    setUser(refreshedUser);
    return refreshedUser;
  };

  return {
    api,
    loginUser,
    logoutUser,
    isLoggedIn,
    refreshAccessToken,
    user,
    refreshUserData,
    getCurrentUserSchoolId,
  };
}
