import React, { createContext, useContext } from 'react';
import { useAuthService } from '../services/authService';
import { PropTypes } from 'prop-types';

const AuthServiceContext = createContext(null);

export function AuthServiceProvider({ children }) {
  const authServices = useAuthService();

  return (
    <AuthServiceContext.Provider value={authServices}>
      {children}
    </AuthServiceContext.Provider>
  );
}

export function useAuthServiceContext() {
  const context = useContext(AuthServiceContext);

  if (context === null) {
    throw new Error('Error - You have to use the AuthServiceProvider');
  }

  return context;
}
// Define propTypes for AuthProvider
AuthServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AuthServiceProvider;
