import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Form,
  Pagination,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/pro-duotone-svg-icons';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import CoachPortalBreadcrumbs from './CoachPortalBreadcrumbs';
import CoachPortalSidebarContext from '../context/CoachPortalSidebarContext';
import { useSchoolService } from '../services/schoolService';
import { useAuthServiceContext } from '../context/authContext';

let sportNameFilter = () => {};
let positionFilter = () => {};
let sharedWithMeFilter = () => {};
let sharedWithMeMobileFilter = () => {};
let assessmentCompleteFilter = () => {};
let assessmentCompleteMobileFilter = () => {};
let usedMyCodeFilter = () => {};
let usedMyCodeMobileFilter = () => {};

const CoachPortalAthleteListing = () => {
  const { user } = useAuthServiceContext();
  const { getSchoolAthleteListForCurrentUser } = useSchoolService();
  const { sidebarState } = useContext(CoachPortalSidebarContext);
  const [data, setData] = useState([]);
  const [sportNameFilterVal, setSportNameFilterVal] = useState('');
  const [positionFilterVal, setPositionFilterVal] = useState('');
  const [filterValueSharedWithMe, setFilterValueSharedWithMe] = useState(false);
  const [filterValueAssessmentComplete, setFilterValueAssessmentComplete] =
    useState(false);
  const [filterValueUsedMyCode, setFilterValueUsedMyCode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const [sortConfig, setSortConfig] = useState({
    key: 'name',
    direction: 'ascending',
  });
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  const filteredData = useMemo(() => {
    if (data.count <= 0) return;
    data.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return -1;
    });

    return data.filter((row) => {
      let searchTermMatch =
        (row.name ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.teams ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.sport_name ?? '')
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        (row.position_name ?? '')
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      let sportFilterMatch = (row.sport_name ?? '')
        .toLowerCase()
        .includes(sportNameFilterVal.toLowerCase());

      let positionFilterMatch = (row.position_name ?? '')
        .toLowerCase()
        .includes(positionFilterVal.toLowerCase());

      let usedReferralCodeMatch = !filterValueUsedMyCode || row.used_my_code;
      let completedAssessmentMatch =
        !filterValueAssessmentComplete || row.completed_initial_assessment;

      return (
        searchTermMatch &&
        sportFilterMatch &&
        positionFilterMatch &&
        usedReferralCodeMatch &&
        completedAssessmentMatch
      );
    });
  }, [
    data,
    searchTerm,
    sportNameFilterVal,
    positionFilterVal,
    /*filterValueSharedWithMe,*/
    filterValueAssessmentComplete,
    filterValueUsedMyCode,
    sortConfig,
  ]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (page) => setCurrentPage(page);

  const getSortDirectionIndicator = (column) => {
    if (sortConfig.key === column) {
      return sortConfig.direction === 'ascending' ? (
        <FontAwesomeIcon icon={faSortDown} />
      ) : (
        <FontAwesomeIcon icon={faSortUp} />
      );
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const formatMainReportData = (text, mobile = false) => {
    // find "has_report" indicator for use later, and remove from display text
    let has_report = text.indexOf('has_report') >= 0;
    text = text.replace('has_report', '');

    let rows = text.split('\n');
    return (
      rows.length > 0 && (
        <>
          {rows.map((row, index) => (
            <div key={index}>
              {row.indexOf('@') >= 0 ? (
                <a href={`mailto:${row}`}>{row}</a>
              ) : row.indexOf('**Teams**') >= 0 ? (
                <div className="teams-header">Teams:</div>
              ) : (
                row !== 'null' && <>{row}</>
              )}
            </div>
          ))}
          {mobile && has_report && (
            <FontAwesomeIcon
              icon={faFileAlt}
              className="mobile-report-indicator"
            />
          )}
        </>
      )
    );
  };

  const reportDateFormatterFn = (cell, row) => {
    // wrap report data in link to report
    if (!row || row.results_url === '') return;

    return <Link to={row.results_url}>{cell}</Link>;
  };

  const filterControlSharedWithMe = (val) => {
    setFilterValueSharedWithMe(val);
  };

  // filter table by "Shared with me" filter
  useEffect(() => {
    if (!filterValueSharedWithMe) {
      sharedWithMeFilter('');
      sharedWithMeMobileFilter('');
    } else {
      sharedWithMeFilter(true);
      sharedWithMeMobileFilter(true);
    }
  }, [filterValueSharedWithMe]);

  const filterControlAssessmentComplete = (val) => {
    setFilterValueAssessmentComplete(val);
  };

  // filter table by "Assessment Complete" filter
  useEffect(() => {
    if (!filterValueAssessmentComplete) {
      assessmentCompleteFilter('');
      assessmentCompleteMobileFilter('');
    } else {
      assessmentCompleteFilter(true);
      assessmentCompleteMobileFilter(true);
    }
  }, [filterValueAssessmentComplete]);

  // filter table by "Used my referral code" filter
  useEffect(() => {
    if (!filterValueUsedMyCode) {
      usedMyCodeFilter('');
      usedMyCodeMobileFilter('');
    } else {
      usedMyCodeFilter(true);
      usedMyCodeMobileFilter(true);
    }
  }, [filterValueUsedMyCode]);

  const filterControlUsedMyCode = (val) => {
    setFilterValueUsedMyCode(val);
  };

  useEffect(() => {
    sportNameFilter(sportNameFilterVal);
    positionFilter(positionFilterVal);
  }, [sportNameFilterVal, positionFilterVal]);

  // when sidebar filter value changes, set the sport and position column filter (hidden) values
  useEffect(() => {
    if (sidebarState === null || sidebarState === undefined) return;
    let filterVal = sidebarState.sport;

    if (sidebarState.gender) {
      if (sidebarState.gender === 'Mixed') {
        filterVal = sidebarState.gender + ' ' + filterVal;
      } else {
        filterVal = sidebarState.gender + "'s " + filterVal;
      }
    }
    setSportNameFilterVal(filterVal);
    setPositionFilterVal(sidebarState.position ?? '');
  }, [sidebarState]);

  // get data
  useEffect(() => {
    if (!user) return;

    // construct a list of this coach's sport names for the "shared with me" filter
    const coach_sports = user.sports.map((sport) => {
      return sport.name;
    });

    // find the coach's school id
    let coach_school = null;
    if (
      user.schools_shared_to !== undefined &&
      user.schools_shared_to.length > 0
    ) {
      coach_school = user.schools_shared_to[0].id;
    }
    if (coach_school === null) return;

    const fetchData = async () => {
      let resdata = await getSchoolAthleteListForCurrentUser();
      // console.log(resdata);
      if (resdata == null) return;
      // if the coach has sports assigned, only show athletes for those
      // sports and delete others; otherwise show all athlets.
      if (coach_sports.length > 0) {
        resdata = resdata.filter((elem) => {
          return coach_sports.includes(elem.sport_name);
        });
      }

      _.forEach(resdata, (elem) => {
        let report_date_formatted = elem.initial_assessment_completion_datetime;
        if (report_date_formatted !== null) {
          report_date_formatted = new Date(
            report_date_formatted,
          ).toLocaleDateString();
        }
        elem.report_date_formatted = report_date_formatted;

        elem.sport_combined = elem.sport_name + '\n' + elem.position_name;

        elem.name = elem.name + '\n' + elem.email;
        elem.combined_data =
          elem.name + '\n' + elem.sport_name + '\n' + elem.position_name;

        if (elem.initial_assessment_id) elem.combined_data += 'has_report';

        elem.is_my_sport = coach_sports.includes(elem.sport_name);
        elem.used_my_code = elem.coach_referral_coach === user.id;

        // create teams list
        elem.teams =
          elem.highschool_team + '\n' + elem.aau_team + '\n' + elem.travel_team;

        // add teams to combined data for mobile display
        if (elem.highschool_team || elem.aau_team || elem.travel_team) {
          elem.combined_data = elem.combined_data +=
            '\n' +
            '**Teams**' +
            '\n' +
            elem.highschool_team +
            '\n' +
            elem.aau_team +
            '\n' +
            elem.travel_team;
        }

        // set results URL
        elem.results_url = '';
        if (elem.initial_assessment_id) {
          elem.results_url = `/results/${elem.initial_assessment_id}/${elem.id}/`;
        }
      });
      setData(resdata);
    };

    fetchData();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const Filters = () => (
    <div style={{ float: 'left', marginBottom: 10 }}>
      {
        /* "My Athletes" filter has been removed, but keeping code in case it's added back */
        false && (
          <Form.Check
            type="switch"
            id="filter-control-sharedwithme"
            data-testid="coachportalathletelisting-filter-myathletes"
            label="My athletes"
            onClick={() => filterControlSharedWithMe(!filterValueSharedWithMe)}
            checked={filterValueSharedWithMe}
            onChange={() => {}}
          />
        )
      }
      <Form.Check
        type="switch"
        id="filter-control-usedmycode"
        label="Used my referral code"
        onClick={() => filterControlUsedMyCode(!filterValueUsedMyCode)}
        checked={filterValueUsedMyCode}
        onChange={() => {}}
      />
      <Form.Check
        type="switch"
        id="filter-control-assessmentcomplete"
        label="Completed results"
        onClick={() =>
          filterControlAssessmentComplete(!filterValueAssessmentComplete)
        }
        checked={filterValueAssessmentComplete}
        onChange={() => {}}
      />
    </div>
  );

  return (
    <div data-testid="coachportalathletelisting-container">
      <h2>Athlete Listing</h2>
      <CoachPortalBreadcrumbs />
      <div className="coach-portal-athlete-listing">
        <div className="d-none d-md-block">
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </InputGroup>
          <Filters />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th onClick={() => requestSort('name')}>
                  Name {getSortDirectionIndicator('name')}
                </th>
                <th onClick={() => requestSort('teams')}>
                  Teams {getSortDirectionIndicator('teams')}
                </th>
                <th onClick={() => requestSort('sport_name')}>
                  Sport {getSortDirectionIndicator('sport_name')}
                </th>
                <th onClick={() => requestSort('position_name')}>
                  Position {getSortDirectionIndicator('position_name')}
                </th>
                <th onClick={() => requestSort('report_date_formatted')}>
                  Report {getSortDirectionIndicator('report_date_formatted')}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((row, index) => (
                <tr key={index}>
                  <td>{formatMainReportData(row.name)}</td>
                  <td>{formatMainReportData(row.teams)}</td>
                  <td>{row.sport_name}</td>
                  <td>{row.position_name}</td>
                  <td>
                    {reportDateFormatterFn(row.report_date_formatted, row)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
        <div className="mobile-view d-md-none"></div>
      </div>
    </div>
  );
};

CoachPortalAthleteListing.propTypes = {
  searchProps: PropTypes.object,
  baseProps: PropTypes.object,
};
export default CoachPortalAthleteListing;
