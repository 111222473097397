import { React, useState } from 'react';
import logo from './images/logo.svg';
import { appURL, publicURL } from '../environment';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState('no');
  const toggle_menu = () => {
    if (menuOpen === 'no') {
      setMenuOpen('yes');
    } else {
      setMenuOpen('no');
    }
  };
  return (
    <header className="lbp">
      <div className="container">
        <a href={`${appURL}`}>
          <img src={logo} id="logo" alt="leftBrain Performance" />
        </a>
        <div id="menu_wrapper1">
          <div id="menu_wrapper2" tabIndex="0" data-open={menuOpen}>
            <a
              className="button"
              href={`${publicURL}/about/`}
              target="_blank"
              rel="noreferrer"
            >
              About Us
            </a>
            <div
              title="Menu"
              className="button"
              onClick={(e) => {
                toggle_menu(e.target);
              }}
            >
              <div id="menu_icon">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div id="menu">
              <a href={`${publicURL}/help/`} target="_blank" rel="noreferrer">
                Help &amp; FAQ
              </a>
              <a
                href={`${publicURL}/contact/`}
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
              <a href={`${publicURL}`} target="_blank" rel="noreferrer">
                Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
