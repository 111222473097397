import React, { useEffect } from 'react';
import { useAuthServiceContext } from '../context/authContext';
import PayMessage from '../components/PayMessage';
import { useNavigate } from 'react-router-dom';
import UnapprovedMessage from '../components/UnapprovedMessage';
import CoachPortalAthleteListing from '../components/CoachPortalAthleteListing';

const PortalPage = () => {
  const { user } = useAuthServiceContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.has_paid && user.type !== 'COACH') {
      if (user.completed_initial_assessment === false) {
        navigate('/assessment');
      } else {
        navigate('/results');
      }
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const CoachPortalView = () => {
    return (
      <>
        {user.coach_extension && user.coach_extension.is_approved ? (
          <CoachPortalAthleteListing />
        ) : (
          <UnapprovedMessage />
        )}
      </>
    );
  };

  return (
    <div>
      {user && !user.has_paid && user.type !== 'COACH' && (
        <PayMessage user={user} />
      )}
      {user && user.type === 'COACH' && <CoachPortalView />}
    </div>
  );
};

export default PortalPage;
