/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Card,
  Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './images/logo.svg';
import { useAuthServiceContext } from '../context/authContext';
import CoachPortalSidebarContext from '../context/CoachPortalSidebarContext';
import Sidebar from './Sidebar';
import { appURL, publicURL } from '../environment';

function LoggedInLayout(props) {
  const { user, isLoggedIn, logoutUser } = useAuthServiceContext();
  const [welcomeMsg, setWelcomeMsg] = useState('Hello!');
  const [sidebarState, setSidebarState] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState('');
  const [isCoach, setIsCoach] = useState(false);
  useEffect(() => {
    if (user) {
      setWelcomeMsg(`Hello, ${user.first_name}!`);
      setIsCoach(user.type === 'COACH');
      var schools = user.schools_shared_to;
      if (schools.length > 0 && schools[0].logo) setSchoolLogo(schools[0].logo);
    }
  }, [user]);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        bg="white"
        variant="light"
        style={{ zIndex: 999 }}
      >
        <Container fluid>
          <Navbar.Brand href={`${appURL}`}>
            <img
              src={logo}
              width="200"
              className="d-inline-block align-top"
              alt="leftBrain Performance logo"
              style={{ margin: 10, marginLeft: 0 }}
            />
          </Navbar.Brand>
          {!isLoggedIn ? (
            <Link to="/signin">Sign In</Link>
          ) : (
            <>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end"
              >
                <Nav className="d-none d-md-flex">
                  {schoolLogo ? (
                    <img
                      src={`${schoolLogo}`}
                      alt="School Logo"
                      className="school-logo"
                    />
                  ) : (
                    <span></span>
                  )}
                  <NavDropdown
                    alignright="true"
                    title={welcomeMsg}
                    id="collapsible-nav-dropdown"
                  >
                    {user && user.type === 'COACH' ? (
                      <></>
                    ) : user &&
                      user.type === 'ATHLETE' &&
                      user.completed_initial_assessment ? (
                      <NavDropdown.Item href="/results">
                        View Results
                      </NavDropdown.Item>
                    ) : (
                      <NavDropdown.Item href="/assessment">
                        Take Assessment
                      </NavDropdown.Item>
                    )}

                    <NavDropdown.Item href="/profile">
                      My Profile
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      href={`${publicURL}/help/`}
                      target="_blank"
                    >
                      Help &amp; FAQ
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href={`${publicURL}/contact/`}
                      target="_blank"
                    >
                      Contact Us
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href={`${publicURL}/about/`}
                      target="_blank"
                    >
                      About Us
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      onClick={() => {
                        logoutUser();
                      }}
                    >
                      Signout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Nav className="d-md-none">
                  {user && user.type === 'COACH' ? (
                    <>
                      <Nav.Item>
                        <Nav.Link href="/portal">Athlete Listing</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        {user.completed_initial_assessment ? (
                          <Nav.Link href="/results">My Report</Nav.Link>
                        ) : (
                          <Nav.Link href="/assessment">
                            Take Assessment
                          </Nav.Link>
                        )}
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="/profile">
                          Find My Referral Link
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  ) : user && user.completed_initial_assessment ? (
                    <Nav.Item>
                      <Nav.Link href="/results">View Results</Nav.Link>
                    </Nav.Item>
                  ) : (
                    <Nav.Item>
                      <Nav.Link href="/assessment">Take Assessment</Nav.Link>
                    </Nav.Item>
                  )}

                  <Nav.Item>
                    <Nav.Link href="/profile">My Profile</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        logoutUser();
                      }}
                    >
                      Signout
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </>
          )}
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <CoachPortalSidebarContext.Provider
            value={{ sidebarState, setSidebarState }}
          >
            {!props.restricted && <Sidebar />}

            <Col
              md={props.restricted || !isCoach ? 12 : 9}
              xl={props.restricted || !isCoach ? 12 : 10}
              className="ml-sm-auto"
            >
              <Card className="mainCard p-3 p-md-5 mx-sm-4 mx-lg-5 my-3 my-sm-4 my-lg-5">
                {props.children}
              </Card>
            </Col>
          </CoachPortalSidebarContext.Provider>
        </Row>
      </Container>
    </>
  );
}

export default LoggedInLayout;
