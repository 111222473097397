import { useAuthServiceContext } from '../context/authContext';

export function useResultService() {
  const { api } = useAuthServiceContext();
  const GetSubvariableDataForResultReport = async (resultId, varId) => {
    try {
      const url = `/results/${resultId}/${varId}/`;
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      return null;
    }
  };

  const GetVariableDataByGroupForResultReport = async (
    athleteId,
    resultId,
    groupId,
  ) => {
    const url = `/results/${resultId}/${athleteId}/${groupId}/`;
    try {
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      return null;
    }
  };

  const GetDirectLinkReportData = async (directLink) => {
    const url = `/results/limited/${directLink}/`;
    try {
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      return null;
    }
  };

  const CheckLieDetection = async (resultId, athleteId) => {
    const url = `/results/${resultId}/LDT/${athleteId}/`;
    try {
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      return null;
    }
  };

  return {
    GetSubvariableDataForResultReport,
    GetVariableDataByGroupForResultReport,
    GetDirectLinkReportData,
    CheckLieDetection,
  };
}
