import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useResultService } from '../services/resultService';
import ReportVariable from './ReportVariable';
import ResultPageLoading from './ResultPageLoading';

const ReportVariableGroup = ({ athleteId = 0, resultId = 0, groupId = 0 }) => {
  const { GetVariableDataByGroupForResultReport } = useResultService();
  const [resultData, setResultData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!athleteId || !resultId) return;

    GetVariableDataByGroupForResultReport(athleteId, resultId, groupId)
      .then((res) => {
        if (res !== undefined) setResultData(res);
        else setResultData('');
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err, 'error - no api call response');
        setResultData('');
        setIsLoaded(true);
      });
  }, [athleteId, resultId, groupId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {resultData
        ? resultData.map((record, index) => {
            return <ReportVariable key={index} {...record} />;
          })
        : !isLoaded && <ResultPageLoading />}
    </>
  );
};

ReportVariableGroup.propTypes = {
  athleteId: PropTypes.string,
  resultId: PropTypes.string,
  groupId: PropTypes.number,
};

export default ReportVariableGroup;
