import { useAuthServiceContext } from '../context/authContext';
import { useSchoolService } from './schoolService';
// import { useApiCall } from '../interceptors/apiInterceptor';

export function useUserService() {
  const { api, user, getCurrentUserSchoolId } = useAuthServiceContext();
  const { getSchoolSportsList } = useSchoolService();
  // const { api } = useApiCall();

  const checkIfUserHasPermissionToViewAthleteReport = async (athleteId) => {
    try {
      const url = `/users/${athleteId}/checkpermission/${user.id}/`;
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      console.log('check permission err', err);
      return err.response.status;
    }
  };

  const getCurrentUserSportList = () => {
    const coach_school = getCurrentUserSchoolId();
    let sports = getSchoolSportsList(coach_school);
    // if coach has sports assigned, limit to those sports
    if (sports && user.sports.length > 0 && sports.length > 0) {
      sports = sports.filter((elem) => {
        return user.sports.filter((elem2) => elem2.id === elem.id).length > 0;
      });
    }
    return sports;
  };

  return {
    getCurrentUserSchoolId,
    getCurrentUserSportList,
    checkIfUserHasPermissionToViewAthleteReport,
  };
}
