import React from 'react';
import { Modal } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { useAuthServiceContext } from '../context/authContext';
const UnapprovedMessage = () => {
  const { logoutUser } = useAuthServiceContext();

  return (
    <Modal
      show={true}
      backdrop="static"
      centered
      style={{ textAlign: 'center' }}
    >
      <Modal.Body>
        <p id="modalTitle">
          Your registration is pending approval. Please check back later.
        </p>
        <div id="modalActions">
          <a
            id="logout"
            href="#"
            onClick={() => {
              logoutUser();
            }}
          >
            Logout
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p>
          Have any questions? please email{' '}
          <a href="mailto:support@leftbrainperformance.com">
            support@leftbrainperformance.com
          </a>
        </p>
      </Modal.Footer>
    </Modal>
  );
};

UnapprovedMessage.propTypes = {
  user: PropTypes.object,
};

export default UnapprovedMessage;
