import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ReportVariableGroup from './ReportVariableGroup';
import { useResultService } from '../services/resultService';
import {
  redFlag,
  blueFlag,
  greenFlag,
  yellowFlag,
  orangeFlag,
} from './images/flags';
import unseen from './images/unseen.svg';
import './css/style.css';
import './css/mobile.css';

const ReportCoach = ({ athleteId, resultId }) => {
  const { CheckLieDetection } = useResultService();
  const [hasLied, setHasLied] = useState(false);

  useEffect(() => {
    // Set up summary labels on component load
    const summaries = document.querySelectorAll('summary');
    summaries.forEach((sum) => {
      sum.setAttribute('title', 'Expand/collapse details');
    });

    //check lie detection score
    CheckLieDetection(resultId, athleteId)
      .then((res) => {
        setHasLied(res);
      })
      .catch((err) => {
        console.log(err, 'error - no api call response');
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="html_container">
        <h1>
          Athlete&rsquo;s Grit<sup>ABC&trade;</sup> Skills Assessment Report
        </h1>
        <p>
          This Results Page summarizes how this athlete compared to other people
          who&rsquo;ve also taken this Assessment with respect to a variety of
          social/emotional/behavioral skills that predict athletic outcomes.
        </p>
        <p></p>
        <details>
          <summary>
            The reported scores are{' '}
            <b>
              <i>percentiles</i>
            </b>
            .
          </summary>
          <p>
            Percentiles tell you how this athlete compared to other athletes
            who&rsquo;ve also taken our Assessment. Scores near the 50th
            percentile mean that the athlete is <b>exactly average</b> (i.e.,
            normal, typical) in a social/emotional/behavioral skill. In
            contrast, a score of 75th percentile, for example, means that the
            athlete scored higher than 75% of other people in that skill. Please
            see our{' '}
            <a
              target="_blank"
              href="https://www.leftbrainperformance.com/results/interpretation/"
              rel="noreferrer"
            >
              Results Interpretation Guide
            </a>{' '}
            for more information.
          </p>
        </details>
        <p></p>
        <details>
          <summary>
            Athletes&rsquo; Results Pages{' '}
            <b>
              <i>differ from what you see</i>
            </b>
            .
          </summary>
          As noted below, some of the social/emotional/behavioral skills
          presented below are <u>not</u> presented to athletes. Athletes also
          receive gentler and more positive feedback, as compared to what is
          provided to you. Athletes&rsquo; Results Pages frame all levels of
          each social/emotional/behavioral skill in a positive light.
        </details>
        <p></p>
        <details>
          <summary>
            Look out for{' '}
            <b>
              <i>colored flags</i>
            </b>
            .
          </summary>
          <p>
            Flags identify concerning and/or noteworthy aspects of the
            athlete&rsquo;s report. Skills with no flags are neither concerning
            nor particularly noteworthy.
          </p>
          <ul>
            <li>
              <b>Red Flag</b> <img className="green_flag" src={redFlag} />
              Athlete&rsquo;s score raises substantial concern. Interview with
              great care.
            </li>
            <li>
              <b>Orange Flag</b>{' '}
              <img className="orange_flag" src={orangeFlag} /> Athlete&rsquo;s
              score raises moderate concern. Interview with care.
            </li>
            <li>
              <b>Yellow Flag</b>{' '}
              <img className="yellow_flag" src={yellowFlag} /> Athlete&rsquo;s
              score raises mild concern. Potentially address via interview.
            </li>
            <li>
              <b>No Flag:</b> Athlete&rsquo;s score is generally fine; it is
              neither concerning nor noteworthy.
            </li>
            <li>
              <b>Green Flag</b> <img className="green_flag" src={greenFlag} />{' '}
              Athlete&rsquo;s score is excellent.
            </li>
            <li>
              <b>Star Flag</b> <img className="blue_flag" src={blueFlag} />
              Athlete&rsquo;s score is of star-level quality.
            </li>
          </ul>
          <p>
            <b>Critically&mdash;nobody is perfect.</b> Thus, athletes should be
            evaluated <i>holistically</i>. Red, orange, and yellow flags
            identify areas of concern that you might give extra consideration to
            while evaluating and interviewing the athlete. However, any one
            single flag should not be used as a binary acceptance/rejection
            litmus test. Again, it is the athlete&rsquo;s{' '}
            <b>
              <i>holistic profile</i>
            </b>{' '}
            that should be considered.
          </p>
        </details>
        <div
          className="variable lie_detection"
          style={{ display: hasLied ? 'block' : 'none' }}
        >
          <big>
            <b>
              <i>
                <u>
                  This athlete triggered our &quot;lie detection&quot;
                  algorithms
                </u>
              </i>
            </b>
          </big>
          <img src={redFlag} className="red_flag" />
          <img src={redFlag} className="red_flag" />
          <img src={redFlag} className="red_flag" />
          <br />
          <br />
          This does not necessarily mean that the athlete was lying while taking
          the Assessment. Rather, the athlete may have been trying to impress
          you and/or give answers that they believed that you wanted to hear.
          <br />
          <br />
          We recommend ultra-extra care while interviewing this athlete.
          <br />
          <br />
          <b>
            <i>
              It may be wise to advise this athlete to retake this Assessment,
              answering each question as honestly as is possible.
            </i>
          </b>
        </div>
        <hr />
        <h1>
          Core-5 Grit<sup>ABC&trade;</sup> Skills
        </h1>
        <p>
          According to contemporary personality science, almost all differences
          in human social/emotional/behavioral skills can be reduced to five
          dimensions
          <cite>Goldberg, 1999; Soto et al., 2022</cite>. This Assessment
          provides you information on how this athlete scored with respect to
          each of these Core-5 Grit Skills, as compared to other people.
        </p>
        <p>
          The Core-5 Skills are ordered roughly with respect to how important
          they are to predicting athletic outcomes (i.e., conscientious grit is
          most important whereas openness grit is least important).
        </p>
        <div className="spacer"></div>
        {
          <ReportVariableGroup
            athleteId={athleteId}
            resultId={resultId}
            groupId={1}
          />
        }
        <hr />
        <h1>
          Grit<sup>ABC&trade;</sup> Sports Motivation{' '}
          <img
            className="not_seen"
            src={unseen}
            title="This trait is NOT shown to athletes on their reports"
          />
        </h1>
        {
          <ReportVariableGroup
            athleteId={athleteId}
            resultId={resultId}
            groupId={2}
          />
        }
        <hr />
        <h1>
          Grit<sup>ABC&trade;</sup> Relational Skills
        </h1>
        <p>
          Relational skills affect everything from how people form friendships
          and romantic partnerships to how they react to bosses and coaches
          telling them what to do{' '}
          <cite>Hazan &amp; Shaver, 1987; Mikulincer &amp; Shaver, 2016</cite>.
          A summary of this athlete&rsquo;s relational skills, as compared to
          other people, is below.
        </p>
        <div className="spacer"></div>
        {
          <ReportVariableGroup
            athleteId={athleteId}
            resultId={resultId}
            groupId={3}
          />
        }
        <hr />
        <h1>
          Grit<sup>ABC&trade;</sup> Dark Tendencies{' '}
          <img
            className="not_seen"
            src={unseen}
            title="This trait is NOT shown to athletes on their reports"
          />
        </h1>
        <p></p>
        <details>
          <summary>
            Everyone has different ways of coping with the various stresses that
            life throws at us.
          </summary>
          This section of the athlete&rsquo;s Results Page describes a curated
          selection of dark social/emotional/behavioral tendencies that some
          people use to navigate the world{' '}
          <cite>Furnham et al., 2013; Jones &amp; Paulhus, 2014</cite>
          &mdash;and how the athlete compared to other people with respect to
          these dark tendencies.
        </details>
        <p></p>
        <details>
          <summary>
            <b>
              <i>Critically</i>
            </b>
            , this Assessment is
            <b>
              <i>
                &nbsp;<u>not</u>&nbsp;
              </i>
            </b>
            a clinical diagnostic tool.
          </summary>
          Thus, this Assessment does not and cannot diagnose individuals with
          any sort of personality disorder or mental illness. Rather, this
          Assessment measures subclinical levels of each dark tendency. It is
          crucial to remember that the 50th percentile of any trait represents
          exactly average&mdash;normal or typical&mdash;individuals. Thus,
          individuals with dark scores near the 50th percentile are exactly
          average, normal, and typical. Only truly extremely high scores on dark
          tendencies should be disconcerting.
        </details>
        <div className="spacer"></div>
        <ReportVariableGroup
          athleteId={athleteId}
          resultId={resultId}
          groupId={4}
        />
      </div>
    </>
  );
};
ReportCoach.propTypes = {
  athleteId: PropTypes.string,
  resultId: PropTypes.string,
};
export default ReportCoach;
