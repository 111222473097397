import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ReportVariableGroup from './ReportVariableGroup';
import './css/style.css';
import './css/mobile.css';

const ReportAthlete = ({ athleteId, resultId }) => {
  useEffect(() => {
    // Set up summary labels on component load
    const summaries = document.querySelectorAll('summary');
    summaries.forEach((sum) => {
      sum.setAttribute('title', 'Expand/collapse details');
    });
  }, []);

  return (
    <>
      <div className="html_container">
        <h1 style={{ marginTop: '0px' }}>
          Grit<sup>ABC&trade;</sup> Skills Assessment Results
        </h1>
        <p>
          This Assessment measured a wide variety of social/emotional/behavioral
          skills that relate to athletes&apos; ability to maximize their
          potential. This Results Page summarizes how you compare to other
          people who&apos;ve also taken this Assessment.* Your scores on each
          social/emotional/behavioral skill are based on what you told us about
          yourself, and how you responded to each question in our Assessment.
        </p>
        <p>
          Your scores reflect how you compare to other people. Medium scores
          mean that you&apos;re exactly average (i.e., normal/normative) in a
          social/emotional/behavioral skill. In contrast, high or low scores
          mean that you are higher or lower (respectively) than most people in
          that particular quality.
        </p>
        <hr />
        <h1>
          Core-5 Grit<sup>ABC&trade;</sup> Skills
        </h1>
        <p>
          According to contemporary personality science, almost all differences
          in human social/emotional/behavioral skills can be reduced to five
          dimensions. This Assessment provides you information on how you score
          with respect to each of these Core-5 Grit Skills, as compared to other
          people.*
        </p>
        <div className="spacer"></div>
        {
          <ReportVariableGroup
            athleteId={athleteId}
            resultId={resultId}
            groupId={1}
          />
        }
        <hr />
        <h1>
          Grit<sup>ABC&trade;</sup> Relational Skills
        </h1>
        <p>
          Your Relational Social/Emotional/Behavioral Skills affect how you form
          relationships, as well as how you react to bosses and coaches telling
          you what to do. A summary of your Grit Relational Skills, as compared
          to other people,* is below.
        </p>
        <div className="spacer"></div>
        {
          <ReportVariableGroup
            athleteId={athleteId}
            resultId={resultId}
            groupId={3}
          />
        }
        <hr />
        <div style={{ fontSize: '.75rem' }}>
          <details>
            <summary>
              <i>
                <small>*Disclaimer</small>
              </i>
            </summary>
            <i>
              <small>
                All results and descriptions in this Assessment detail how your
                answers to our questions compare to others who&apos;ve also
                taken this Assessment. Thus, our results pages compare you to
                other athletes. Our results pages and summaries are based on
                current scientific evidence. Nevertheless, they require
                consistently honest responses to provide an accurate, holistic
                picture of you as an athlete. Please note that our Assessments
                contain several lie-detection techniques, the results of which
                are reported to coaches at organizations to which you apply.
              </small>
            </i>
          </details>
        </div>
      </div>
    </>
  );
};
ReportAthlete.propTypes = {
  athleteId: PropTypes.string,
  resultId: PropTypes.string,
};
export default ReportAthlete;
