import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

const ReportPercentileBar = ({
  percentileValue,
  displayText,
  colorNumber,
  showBar,
}) => {
  const [style, setStyle] = useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${percentileValue}%`,
    };

    setStyle(newStyle);
  }, 200);

  return (
    <>
      {showBar ? (
        <div className="progress" data-testid="reportpercentilebar">
          <div className={`progress-done ${colorNumber}`} style={style}>
            {displayText}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

ReportPercentileBar.propTypes = {
  percentileValue: PropTypes.number,
  displayText: PropTypes.string,
  colorNumber: PropTypes.string,
  showBar: PropTypes.bool,
};

export default ReportPercentileBar;
