import React, { useContext } from 'react';
import CoachPortalSidebarContext from '../context/CoachPortalSidebarContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';

const CoachPortalSidebar = () => {
  const { sidebarState, setSidebarState } = useContext(
    CoachPortalSidebarContext,
  );

  const clickBreadcrumb = (e, type) => {
    e.preventDefault();
    if (type === 'all') {
      setSidebarState({
        ...sidebarState,
        sport: '',
        gender: null,
        position: null,
      });
    } else if (type === 'sport') {
      setSidebarState({ ...sidebarState, gender: null, position: null });
    } else if (type === 'gender') {
      setSidebarState({ ...sidebarState, position: null });
    } else {
      // do nothing - position is the last entry and doesn't need to limit the filters
    }
  };

  return (
    <div className="coach-portal-breadcrumbs d-none d-md-block">
      &nbsp; {/*this &nbsp; holds the vertical space for the breadcrumbs div */}
      {sidebarState && (
        <>
          {sidebarState.sport && (
            <>
              <a href="#" onClick={(e) => clickBreadcrumb(e, 'all')}>
                All Sports
              </a>
              <FontAwesomeIcon icon={faAngleRight} />
              <a href="#" onClick={(e) => clickBreadcrumb(e, 'sport')}>
                {sidebarState.sport}
              </a>
            </>
          )}
          {sidebarState.gender && (
            <>
              <FontAwesomeIcon icon={faAngleRight} />
              <a href="#" onClick={(e) => clickBreadcrumb(e, 'gender')}>
                {sidebarState.gender}
              </a>
            </>
          )}
          {sidebarState.position && (
            <>
              <FontAwesomeIcon icon={faAngleRight} />
              <a href="#" onClick={(e) => clickBreadcrumb(e, 'position')}>
                {sidebarState.position}
              </a>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default CoachPortalSidebar;
