import React from 'react';
import fbIcon from '../img/template/fb.png';
import twitIcon from '../img/template/twitter.png';
import instaIcon from '../img/template/insta.png';
import tikIcon from '../img/template/tiktok.png';
import linkedIcon from '../img/template/linkedin.png';
import footerLogo from '../img/template/logo_white.svg';
import { publicURL } from '../environment';
export default function Footer() {
  return (
    <footer>
      <div id="footer_grid" className="container grid_1_1">
        <div>
          <div className="spacer" style={{ marginBottom: 10 }}></div>
          <div id="media_icons">
            <a
              href="https://www.facebook.com/leftBrainPerformance/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Facebook" title="Facebook" src={fbIcon} />
            </a>
            <a
              href="https://twitter.com/leftBrainPerf/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Twitter" title="Twitter" src={twitIcon} />
            </a>
            <a
              href="https://www.instagram.com/leftBrainPerformance/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Instagram" title="Instagram" src={instaIcon} />
            </a>
            <a
              href="https://www.tiktok.com/@leftBrainPerformance/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="TikTok" title="TikTok" src={tikIcon} />
            </a>
            <a
              href="https://www.linkedin.com/company/leftBrainPerformance/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="LinkedIn" title="LinkedIn" src={linkedIcon} />
            </a>
          </div>
          <div className="spacer"></div>
          ©2020-2021{' '}
          <a
            href="https://leftbrainperformance.com"
            target="_blank"
            rel="noreferrer"
          >
            leftBrain Performance
          </a>
          <br />
          Canada, All Rights Reserved
        </div>
        <div>
          <a
            href="https://leftbrainperformance.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={footerLogo}
              alt="leftBrain Performance"
              title="leftBrain Performance"
              id="footer_logo"
            />
          </a>
          <br />
          <a href="/signin">Sign In</a>
          <br />
          <a href={`${publicURL}/about/`} target="_blank" rel="noreferrer">
            About Us
          </a>
          <br />
          <a href={`${publicURL}/help/`} target="_blank" rel="noreferrer">
            Help &amp; FAQ
          </a>
          <br />
          <a href={`${publicURL}/terms/`} target="_blank" rel="noreferrer">
            Terms &amp; Conditions
          </a>
          <br />
          <a href={`${publicURL}/privacy/`} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          <br />
          <a href={`${publicURL}/contact/`} target="_blank" rel="noreferrer">
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  );
}
