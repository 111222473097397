import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthServiceContext } from '../context/authContext';
import { useUserService } from '../services/userService';
import Alert from 'react-bootstrap/Alert';
import { Col, Row } from 'react-bootstrap';
import ResultPageLoading from '../components/ResultPageLoading';
import ReportCoach from '../components/ReportCoach';
import ReportAthlete from '../components/ReportAthlete';

const ResultPage = () => {
  const { user } = useAuthServiceContext();
  const { checkIfUserHasPermissionToViewAthleteReport } = useUserService();

  const { result, athlete } = useParams();
  const [athleteId, setAthleteId] = useState('');
  const [resultId, setResultId] = useState('');
  const [permission, setPermission] = useState(null);
  const [reportUser, setReportUser] = useState(null);
  const [loadingOwnResults, setLoadingOwnResults] = useState(false);

  useEffect(() => {
    if (!user) return;

    if (user.type === 'ATHLETE') {
      // if there are athleteId and resultId in the URL, reject because this is
      // the incorrect URL for athlete's own results
      if (athlete || result) {
        setPermission(false);
      } else {
        setAthleteId(user.id);
        setResultId('0'); //0 to request athletes latest
        setPermission(true);
        setReportUser(user);
        setLoadingOwnResults(true);
      }
      return;
    }

    // if this is a coach but there are no athleteId and resultId, they want to look at their own results
    if (user.type === 'COACH' && !athlete && !result) {
      console.log('view own results');
      setAthleteId(user.id);
      setResultId('0'); //0 to request athletes latest
      setPermission(true);
      setReportUser(user);
      setLoadingOwnResults(true);
      return;
    }

    // if this coach doesn't have a school, not allowed to look at any users
    if (user.schools_shared_to.length === 0) {
      setPermission(false);
      return;
    }

    // does this coach have permission to this athlete's report?
    // the check will return the user object of the athlete ID provided
    checkIfUserHasPermissionToViewAthleteReport(athlete)
      .then((res) => {
        setAthleteId(athlete);
        setResultId(result);
        setPermission(true);
        setReportUser(res);
      })
      .catch(() => {
        // something went wrong, so just block permission
        setPermission(false);
      });
  }, [user, athlete, result]); // eslint-disable-line react-hooks/exhaustive-deps

  const goBack = () => {
    history.back();
  };

  return (
    <div data-testid="results-container" className="page-results">
      <>
        {permission ? (
          <>
            {user.type === 'COACH' && reportUser && !loadingOwnResults && (
              <>
                <div className="mb-2">
                  <a href={'#'} onClick={goBack} className="mb-2">
                    &lt; Back
                  </a>
                </div>
                <Alert
                  variant="info"
                  className="coach-view-athlete-info"
                  data-testid="coach-report-athlete-info"
                >
                  <div className="coach-report-athlete-info-title">
                    Athlete Info
                  </div>
                  <hr />
                  <Row>
                    <div className="col-lg-6">
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={5}
                          className="coach_report-athlete-info-header"
                        >
                          First Name
                        </Col>
                        <Col md={8} lg={7}>
                          {reportUser.first_name}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={5}
                          className="coach_report-athlete-info-header"
                        >
                          Last Name
                        </Col>
                        <Col md={8} lg={7}>
                          {reportUser.last_name}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0 mb-md-4">
                        <Col
                          md={4}
                          lg={5}
                          className="coach_report-athlete-info-header"
                        >
                          Email
                        </Col>
                        <Col md={8} lg={7}>
                          <a href={`mailto:${reportUser.email}`}>
                            {reportUser.email}
                          </a>
                        </Col>
                      </Row>
                      {reportUser.highschool_team && (
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            Highschool Team
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.highschool_team}
                          </Col>
                        </Row>
                      )}
                      {reportUser.aau_team && (
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            AAU Team
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.aau_team}
                          </Col>
                        </Row>
                      )}
                      {reportUser.travel_team && (
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            Travel Team
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.travel_team}
                          </Col>
                        </Row>
                      )}
                      {reportUser.sports.length > 0 && (
                        <Row className="mb-2 mb-sm-0 mt-md-4">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            Sport
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.sports[0].name}
                          </Col>
                        </Row>
                      )}
                      {reportUser.position && (
                        <Row className="mb-2 mb-sm-0">
                          <Col
                            md={4}
                            lg={5}
                            className="coach_report-athlete-info-header"
                          >
                            Position
                          </Col>
                          <Col md={8} lg={7}>
                            {reportUser.position.name}
                          </Col>
                        </Row>
                      )}
                    </div>

                    <div className="col-lg-6 mt-md-4 mt-lg-0">
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={6}
                          className="coach_report-athlete-info-header"
                        >
                          Registered
                        </Col>
                        <Col md={8} lg={6}>
                          {new Date(
                            reportUser.date_joined,
                          ).toLocaleDateString()}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={6}
                          className="coach_report-athlete-info-header"
                        >
                          Completed
                        </Col>
                        <Col md={8} lg={6}>
                          {reportUser.results.length > 0 ? (
                            <>
                              {new Date(
                                reportUser.results[0].created_datetime,
                              ).toLocaleDateString()}
                            </>
                          ) : (
                            <span>n/a</span>
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={6}
                          className="coach_report-athlete-info-header"
                        >
                          Shared Before Completion?
                        </Col>
                        <Col md={8} lg={6}>
                          {reportUser.results.length > 0 ? (
                            <>
                              {reportUser.results[0].shared_before_completion
                                ? 'Yes'
                                : 'No'}
                            </>
                          ) : (
                            <span>n/a</span>
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-0">
                        <Col
                          md={4}
                          lg={6}
                          className="coach_report-athlete-info-header"
                        >
                          Used my referral code?
                        </Col>
                        <Col md={8} lg={6}>
                          {reportUser.coach_referral_coach ? (
                            <>
                              {reportUser.coach_referral_coach.id === user.id
                                ? 'Yes'
                                : 'No'}
                            </>
                          ) : (
                            <span>No</span>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </Alert>
              </>
            )}
            {user.type === 'ATHLETE' ? (
              <ReportAthlete
                athleteId={String(athleteId)}
                resultId={String(resultId)}
              />
            ) : (
              <ReportCoach
                athleteId={String(athleteId)}
                resultId={String(resultId)}
              />
            )}
          </>
        ) : permission == null ? (
          <ResultPageLoading />
        ) : (
          <Alert variant="danger" data-testid="permission-denied-msg">
            Permission denied.
          </Alert>
        )}
      </>
    </div>
  );
};

export default ResultPage;
