import { Navigate } from 'react-router-dom';
import { useAuthServiceContext } from '../context/authContext';
import React from 'react';
import { PropTypes } from 'prop-types';

const PrivateRoute = ({ children }) => {
  let { isLoggedIn } = useAuthServiceContext();

  return !isLoggedIn ? <Navigate to="/signin" /> : children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
