import { useAuthServiceContext } from '../context/authContext';
// import { useApiCall } from '../interceptors/apiInterceptor';

export function useSchoolService() {
  const { api, getCurrentUserSchoolId } = useAuthServiceContext();
  // const { api } = useApiCall();

  const getSchoolSportsList = async (schoolId) => {
    try {
      const url = `/schools/${schoolId}/sports/`;
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      return err.response.status;
    }
  };

  const getSchoolAthleteList = async (schoolId) => {
    try {
      const url = `/schools/${schoolId}/users/`;
      const res = await api.get(url);
      return res.data;
    } catch (err) {
      return err.response.status;
    }
  };

  const getSchoolAthleteListForCurrentUser = async () => {
    try {
      const coach_school = getCurrentUserSchoolId();
      const res = await api.get(`/schools/${coach_school}/users/`);
      return res.data;
    } catch (err) {
      return null;
    }
  };

  return {
    getSchoolSportsList,
    getSchoolAthleteList,
    getSchoolAthleteListForCurrentUser,
  };
}
